import styled from "styled-components"

const CopyrightContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
`

CopyrightContainer.link = styled.a`
  color: #2196f3 !important;
  text-decoration: none !important;
`

export default CopyrightContainer
