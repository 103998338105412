import React from "react";
import CopyrightContainer from "./copyright.styles";
import Typography from "@eig-builder/core-ui/Typography";

const CopyrightComponent = () => {
  return (
    <CopyrightContainer>
      <Typography variant="h1" gutterBottom>
        Copyright Infringement Policy
      </Typography>
      <Typography variant="body1" gutterBottom>
        Sitebuilder takes intellectual property rights very seriously and it is
        our policy to respond to clear notices of alleged copyright
        infringement. This Copyright Infringement Policy describes the
        information that should be present in these notices. It is designed to
        make submitting notices of alleged infringement to us as straightforward
        as possible while reducing the number of notices that we receive that
        are fraudulent or difficult to verify.
      </Typography>
      <br />
      <Typography variant="h1" gutterBottom>
        Copyright Infringement Policy
      </Typography>
      <br />
      <Typography variant="body1" gutterBottom>
        To file a notice of alleged infringement with us, you must provide a
        written notice that includes your full contact information and sets
        forth the items specified below.
      </Typography>
      <ol>
        <li>
          <Typography variant="body1" gutterBottom>
            The name, address, phone number, email address (if available) and
            physical or electronic signature of the copyright owner or a person
            authorized to act on the copyright owner’s behalf;
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            Identification of the copyrighted work(s);
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            Identification of the infringing material you are asking us to
            remove or disable, and the Internet location of the infringing
            material;
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            A statement that you have a good faith belief that use of the
            disputed material is not authorized by the copyright owner, its
            agent or the law; and
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            A statement that the information in the complaint is accurate, and
            under penalty of perjury, that you are authorized to act on behalf
            of the owner of an exclusive right that is allegedly infringed.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom>
            Your signature
          </Typography>
        </li>
      </ol>
      <Typography variant="body1" gutterBottom>
        Please email the signed notification to{" "}
        <CopyrightContainer.link href="mailto:abuse@Sitebuilder.com">
          abuse@Sitebuilder.com
        </CopyrightContainer.link>
        .
      </Typography>
      <Typography variant="body1" gutterBottom>
        Alternatively, you may mail the signed notification to the following
        address:
      </Typography>
      <Typography variant="body1">
        Newfold Digital
      </Typography>
      <Typography variant="body1">5335 Gate Pkwy.</Typography>
      <Typography variant="body1">2nd Floor</Typography>
      <Typography variant="body1">Jacksonville, FL 32256</Typography>
      <Typography variant="body1" gutterBottom>
        USA
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please note that you will be liable for damages (including costs and
        attorneys' fees) if you materially misrepresent that material is
        infringing your copyright. Accordingly, if you are not sure whether
        material available online infringes your copyright, we suggest that you
        first contact an attorney.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Sitebuilder may respond to take-down notices by removing or disabling
        access to the allegedly infringing material and/or by terminating
        services. If we remove or disable access in response to such a notice,
        we will make a good-faith attempt to contact the owner or administrator
        of the affected site or content.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Sitebuilder may document notices of alleged infringement it receives
        and/or on which we action is taken. As with all legal notices, a copy of
        the notice may be made available to the public and sent to one or more
        third parties who may make it available to the public.
      </Typography>
      <Typography variant='body1' gutterBottom>
          Last updated: October 07, 2022
        </Typography>
    </CopyrightContainer>
  );
};

export default CopyrightComponent;
